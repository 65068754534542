import React, {useState, useEffect } from 'react';
import { 
    Paper, 
    Container,
} from '@mui/material';
import './style.css';
import MainNav from '../../components/MainNav/MainNav';
import { useNavigate } from "react-router-dom";
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

const Login = () => {
    const navigate = useNavigate();
    const { authStatus } = useAuthenticator(context => [context.authStatus]);

    useEffect(() => {
        if(authStatus === 'authenticated'){
            navigate('/dashboard');
        }
       
    }, [navigate, authStatus])
  
    const formFields = {
        signUp: {
            name: {
                order: 1,
                placeholder: 'First name',
                isRequired: true,
                label: 'First name:'
            },
            family_name: {
                order: 2,
                placeholder: 'Last name',
                isRequired: true,
                label: 'Last name'
            },
        },
    }

    return (
        <>
        <MainNav/>
        <Container sx={{paddingTop: '8rem', display: 'flex', justifyContent: 'center'}}
        >
            <Paper elevation={3} sx={{padding: '4px', width: 'fit-content', minWidth: '325px'}}>
                <Authenticator formFields={formFields}/>
            </Paper>
        </Container>
        </>
    );
    // return (
    //     <Container 
    //     //sx={{paddingTop: '8rem', display: 'flex', justifyContent: 'center'}}
    //     >
    //         <MainNav/>
    //         <Paper elevation={3} sx={{padding: '4px', width: 'fit-content', minWidth: '325px'}}>
    //             <Authenticator formFields={formFields}/>
    //         </Paper>
    //     </Container>
    // );
};

export default Login;


// import React, {useState, useEffect } from 'react';
// import { 
//     Paper, 
//     Container,
//     Box, 
//     Stack,
//     IconButton, 
//     InputAdornment, 
//     TextField,
//     Typography,
//     Button
// } from '@mui/material';
// import './style.css';
// import MainNav from '../../components/MainNav/MainNav';
// // import { Amplify } from 'aws-amplify';
// import { signIn } from 'aws-amplify/auth';
// import { AUTH_USER_TOKEN_KEY } from '../../utils/constants';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
// import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

// const Login = ({props}) => {
//     const [email, setEmail] = useState('');
//     // const [username, setUsername] = useState('');
//     const [emailErrorMessage, setEmailErrorMessage] = useState('');
//     const [password, setPassword] = useState('');
//     const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();
//     const { user, authStatus, signOut } = useAuthenticator(context => [context.authStatus]);

//     const handleSignOut = () => {
//         signOut();
//         navigate('/');
//     }
//     const validateEmail = (value) => {
//         if(value === ''){
//             setEmailErrorMessage('email is required');
//             return false;
//         }else{
//             setEmailErrorMessage('');
//             return true;
//         }
//     }
//     const validatePassword = (value) => {
//         if(value === ''){
//             setPasswordErrorMessage('password is required');
//             return false;
//         }else{
//             setPasswordErrorMessage('');
//             return true;
//         }
//     }

//     const handleLoginClicked = () => {
//         console.log('handleLoginClicked');
//         let emailValid = validateEmail(email);
//         let passwordValid = validatePassword(password);
//         if(emailValid && passwordValid){
//             setLoading(true);
//             // const username = email;

//             signIn({username: email, password})
//             .then(user => {
//                 console.log('user: ', user)
//                 localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);

//               navigate('/search');
//             })
//             .catch(err => {
//               console.log(err);
//               setPasswordErrorMessage(err.message)
//               setLoading(false);
              
//             });
//         }
//     }

//     const handleKeyDown = (e) => {
//         if(e.keyCode === 13 && e.target.value.length > 0){
//             if(e.target.name === 'email'){
//                 document.getElementById('password').focus();
//             }
//             if(e.target.name === 'password'){
//                 handleLoginClicked();
//             }
//         }
//     }
  
//     const formFields = {
//         signUp: {
//             name: {
//                 order: 1,
//                 placeholder: 'First name',
//                 isRequired: true,
//                 label: 'First name:'
//             },
//             family_name: {
//                 order: 2,
//                 placeholder: 'Last name',
//                 isRequired: true,
//                 label: 'Last name'
//             },
//             // username: {
//             //     labelHidden: false,
//             //     placeholder: 'Enter your username here',
//             //     isRequired: true,
//             //     label: 'Username:'
//             // },
//         },
//     }

//     return (
//         <>
//         <Container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
//             <MainNav/>
//             <Paper elevation={3} sx={{padding: '4px'}}>
//                 { authStatus === 'authenticated' ? 
//                     <Stack spacing={3}>
//                         <Typography variant='h5'>
//                             Signed in as: {(user && user.username) && user.username}
//                         </Typography>
//                         <Button onClick={handleSignOut}>Sign out</Button>
//                         <Button onClick={() => {console.log(user)}}>Log user details</Button>
//                     </Stack>
//                 :
//                     <Authenticator formFields={formFields}/>
//                 }
//                     {/* <Stack spacing={3}>
//                         <Typography variant='h5'>Login</Typography>
//                         <TextField
//                             id="email"
//                             label="Email"
//                             name="email"
//                             value={email}
//                             onChange={(event) => setEmail(event.target.value)}
//                             onBlur={(e) => validateEmail(e.target.value)}
//                             onKeyDown={handleKeyDown}
//                             error={emailErrorMessage !== ''}
//                             helperText={emailErrorMessage}
//                         />
//                         <TextField
//                             id="password"
//                             label="Password"
//                             name="password"
//                             value={password}
//                             onChange={(event) => setPassword(event.target.value)}
//                             onBlur={(e) => validatePassword(e.target.value)}
//                             onKeyDown={handleKeyDown}
//                             type="password"
//                             error={passwordErrorMessage !== ''}
//                             helperText={passwordErrorMessage}
//                         />
//                         <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
//                             <Button onClick={handleLoginClicked}>Login</Button>
//                         </Box>
//                     </Stack> */}
                
//             </Paper>
//         </Container>
//         </>
//     );
// };

// export default Login;
