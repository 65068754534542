import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home/Home';
import Search from './pages/Search/Search';
import Login from './pages/Login/Login';
import { Amplify } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import aws_exports from './aws-exports';
import DashboardHome from './pages/Dashboard/DashboardHome/DashboardHome';
// import config from './amplifyconfiguration.json';

// Amplify.configure(config);
Amplify.configure(aws_exports);


function App() {
  const { route } = useAuthenticator(context => [context.route]);

  return (
    <div className="App" style={{}}>

      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/search" element={<Search />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/dashboard" element={route === 'authenticated' ? <DashboardHome/> : <Login />}/>
            {/* <Route path="/dashboard/settings" element={route === 'authenticated' ? <DashboardSettings/> : <Login />}/> */}
          </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
