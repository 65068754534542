import React, {useState, useEffect} from 'react';
import { Box, Dialog, DialogTitle, TextField } from '@mui/material';

const AddDomainsDialog = ({open, handleClose}) => {
    
    return (
        <Dialog open={open} onClose={handleClose} >
            <DialogTitle>Add Domains</DialogTitle>

            <Box sx={{width: '300px'}}>
                <TextField aria-label="add-domains" rows={3} multiline placeholder="Add domains separated by a space or newline" />
            </Box>
            
        </Dialog>
    );
   
};

export default AddDomainsDialog;