import React, {useState, useEffect} from 'react';
import { Drawer, Box, Typography, Divider, IconButton, Stack } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import BuyNowBtn from '../EscrowButtons/BuyNowBtn/BuyNowBtn';
import EscrowLogo from '../../assets/media/escrow-com-seeklogo.com-2.svg';
import MakeOfferBtn from '../EscrowButtons/MakeOfferBtn/MakeOfferBtn';

const DomainDrawer = ({open, domain, toggleDrawer}) => {
    
    return (
        <>
        <React.Fragment key='left'>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(null, false)}
            >
                <Box sx={{width: 650,  maxWidth: '85vw'}} role='presentation'>
                    <Stack sx={{padding: '1.5em'}} spacing={3}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '1.5em'}}>
                            <Typography variant='h6'>{domain ? domain.fqdn : null}</Typography>
                            
                            <IconButton
                                aria-label="close-domain-drawer"
                                onClick={toggleDrawer(null, false)}
                                >
                                <ClearIcon/>
                            </IconButton>
                        </Box>
                        {domain && 
                        <>
                            <Typography variant='p'>Details:</Typography>
                            <img src={EscrowLogo} alt="escrow.com" style={{maxWidth: '350px', minWidth: '280px'}}/>
                            {domain.list_price > 0 &&
                                <BuyNowBtn domain={domain}/>
                            }
                            
                            <MakeOfferBtn domain={domain}/>
                        </>}
                        {/* <form action="https://www.escrow-sandbox.com/checkout" method="post" target="_blank">
                            <input type="hidden" name="type" value="domain_name"/>
                            <input type="hidden" name="non_initiator_email" value="sales@domainline.com"/>
                            <input type="hidden" name="non_initiator_id" value="1129661"/>
                            <input type="hidden" name="non_initiator_role" value="seller"/>
                            <input type="hidden" name="title" value="Purchase of domain name: tuds.com"/>
                            <input type="hidden" name="currency" value="USD"/>
                            <input type="hidden" name="domain" value="tuds.com"/>
                            <input type="hidden" name="price" value="10000"/>
                            <input type="hidden" name="concierge" value="false"/>
                            <input type="hidden" name="with_content" value="false"/>
                            <input type="hidden" name="inspection_period" value="1"/>
                            <input type="hidden" name="fee_payer" value="seller"/>
                            <input type="hidden" name="return_url" value=""/>
                            <input type="hidden" name="button_types" value="both"/>
                            <input type="hidden" name="auto_accept" value=""/>
                            <input type="hidden" name="auto_reject" value=""/>
                            <input type="hidden" name="item_key" value="ffff-ffff-tuds-ffff"/>
                            <button class="" type="submit">Buy With Escrow.com</button>
                            <img src="https://t.escrow-sandbox.com/1px.gif?name=bin&price=10000&title=Purchase%20of%20domain%20name%3A%20tuds.com&user_id=1129661" style={{display: "none"}} alt="escrow-img"/>
                        </form> */}

                        {/* <form action="https://www.escrow-sandbox.com/offer" method="post" target="_blank">
                            <input type="hidden" name="type" value="domain_name"/>
                            <input type="hidden" name="non_initiator_email" value="sales@domainline.com"/>
                            <input type="hidden" name="non_initiator_id" value="1129661"/>
                            <input type="hidden" name="non_initiator_role" value="seller"/>
                            <input type="hidden" name="title" value="Purchase of domain name: tuds.com"/>
                            <input type="hidden" name="currency" value="USD"/>
                            <input type="hidden" name="domain" value="tuds.com"/>
                            <input type="hidden" name="price" value="10000"/>
                            <input type="hidden" name="concierge" value="false"/>
                            <input type="hidden" name="with_content" value="false"/>
                            <input type="hidden" name="inspection_period" value="1"/>
                            <input type="hidden" name="fee_payer" value="seller"/>
                            <input type="hidden" name="return_url" value=""/>
                            <input type="hidden" name="button_types" value="both"/>
                            <input type="hidden" name="auto_accept" value=""/>
                            <input type="hidden" name="auto_reject" value=""/>
                            <input type="hidden" name="item_key" value="ffff-ffff-tuds-ffff"/>
                            <button class="" type="submit">Make An Offer</button>
                            <img src="https://t.escrow-sandbox.com/1px.gif?name=bin&price=10000&title=Purchase%20of%20domain%20name%3A%20tuds.com&user_id=1129661" style={{display: "none"}} alt="escrow-img"/>

                        </form> */}
                        
                    </Stack>
                </Box>
            </Drawer>
        </React.Fragment>
        
        </>
    );
   
};

export default DomainDrawer;