import React, {useState, useEffect} from 'react';
import {  
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { FiltersService } from '../../../services/Filters/Filters.service';



const SortDropdown = ({uniqueId}) => {
    const sortOptions = FiltersService.sortOptions;
    const [selectedSort, setSelectedSort] = useState(FiltersService.filters.selectedSort)

    useEffect(() => {
        FiltersService.subscribe(uniqueId, handleFiltersUpdated);
    
        return () => {
            FiltersService.unsubscribe(uniqueId);
        }
    }, []);
    
    const  handleFiltersUpdated = (filters) => {
        if(filters.selectedSort.label !== selectedSort.label){
            setSelectedSort(filters.selectedSort);
        }
    }

    const handleSortChange = (e) => {
        sortOptions.forEach(op => {
            if(op.label === e.target.value){
                FiltersService.setFilter('selectedSort', op)
            }
        })
    }
  
    return (
        <FormControl 
            //variant="standard"
            sx={{ m: 1, minWidth: 120 }} size="small">
            {/* <InputLabel id="sort-selection">Sort</InputLabel> */}
            <Select
                // labelId="sort-selection"
                id="sort-select"
                value={selectedSort.label}
                onChange={handleSortChange}
                // label="Sort"
                inputProps={{ 'aria-label': 'Sort order' }}
            >
                {sortOptions.map(op => <MenuItem key={op.label} value={op.label}>{op.label}</MenuItem>)}
            </Select>
      </FormControl>
    );
};

SortDropdown.defaultProps = {
    uniqueId: 'sort-dropdown'
}

export default SortDropdown;