import React, {useState, useEffect} from 'react';
import {  
    Button,
    Box
} from '@mui/material';
import { FiltersService } from '../../../services/Filters/Filters.service';



const DomainStyleButtons = ({uniqueId}) => {
    const [selectedStyles, setSelectedStyles] = useState(FiltersService.filters.styles);

    useEffect(() => {
        FiltersService.subscribe(uniqueId, handleFiltersUpdated);
    
        return () => {
            FiltersService.unsubscribe(uniqueId);
        }
    }, [uniqueId]);
    
    const  handleFiltersUpdated = (filters) => {
        setSelectedStyles(filters.styles);
    }

    const handleStyleSelected = (style) => {
        console.log('style clicked: ', style)
        
        if(style === 'All'){
            FiltersService.setFilter('styles', [])
        }else{
            if(selectedStyles.includes(style)){
                FiltersService.setFilter('styles', selectedStyles.filter(s => s !== style))
            }else{
                FiltersService.setFilter('styles', [...selectedStyles, style])
            }
        }
    }
  
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Button
                onClick={() => handleStyleSelected('All')}
                variant={selectedStyles.length === 0 ? 'contained' : 'outlined'}
                size="small"
            >
                All
            </Button>

            {FiltersService.styleOptions.map(op => 
                <Button 
                    key={`style-${op}`} 
                    onClick={() => handleStyleSelected(op)}
                    variant={selectedStyles.includes(op) ? 'contained' : 'outlined'}
                    size="small"
                >
                    {op}
                </Button>
            )}
        </Box>
        
    );
};

DomainStyleButtons.defaultProps = {
    uniqueId: 'domain-style-buttons'
}

export default DomainStyleButtons;