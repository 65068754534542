import React, {useState, useEffect} from 'react';
import { 
    Box, 
    Button, 
    Chip,
    Stack
} from '@mui/material';
import { FiltersService } from '../../services/Filters/Filters.service';
import { formatCurrency } from '../../utils/util_functions';
// import { api } from '../../services/Api/Api';
// import { getCurrentUser } from 'aws-amplify/auth';
// import allDomains from "../../assets/data/domains.json";
const FiltersBar = ({toggleFilterDrawer}) => {
    const [filters, setFilters] = useState(FiltersService.filters);

    useEffect(() => {
        FiltersService.subscribe('FiltersBar', handleFiltersUpdated);
    
        return () => {
            FiltersService.unsubscribe('FiltersBar');
        }
    }, []);
    
    const  handleFiltersUpdated = (filters) => {
        console.log("filters changed: ", filters)
        setFilters(filters)
    }

    const handleDeleteSearchTerm = (term) => {
        FiltersService.setFilter('searchTerms', filters.searchTerms.filter(t => t !== term))
    }

    const handleClearPriceFilter = () => {
        FiltersService.filters = {
            ...FiltersService.filters,
            minPrice: 0,
            maxPrice: FiltersService.absoluteMaxPrice
        }
    }

    const handleRemoveStyleFilter = (style) => {
        FiltersService.setFilter('styles', filters.styles.filter(s => s !== style))
    }

    const handleRemoveExtensionFilter = (extension) => {
        FiltersService.setFilter('extensions', filters.extensions.filter(e => e !== extension))
    }

    const handleRemoveBINOnly = () => {
        FiltersService.setFilter('includeMakeOffer', true);
    }

    const handleRemoveCategories = () => {
        FiltersService.setFilter('categoryIds', []);
    }

    // const handleCreateCategories = () => {
    //     FiltersService.createAllCategories()
    //     .then(res => {
    //         console.log("categories created: ", res);
    //     })
    //     .catch(err => {
    //         console.log("failed to create categories: ", err);
    //     })
    // }

    // const handleFetchCategories = () => {
    //     FiltersService.fetchCategories()
    //     .then(res => {
    //         console.log("fetched categories: ", res);
    //     })
    //     .catch(err => {
    //         console.log("failed to fetch categories: ", err);
    //     })
    // }

    // const handleDeleteCategory = () => {
    //     FiltersService.deleteCategory()
    //     .then(res => {
    //         console.log("deleted category: ", res);
    //     })
    //     .catch(err => {
    //         console.log("failed to delete category: ", err);
    //     })
    // }

    // const handleFetchDomains = () => {
    //     api.getDomains('tatehakert@gmail.com')
    //     .then(res => {
    //         console.log('fetchDomains res: ', res);
    //     })
    //     .catch(err => {
    //         console.log("failed to fetch domains: ", err);
    //     })
    // }

    // const handleAddCategory = () => {
    //     FiltersService.addCategory()
    //     .then(res => {
    //         console.log('addCategory res: ', res);
    //     })
    //     .catch(err => {
    //         console.log("failed to addCategory: ", err);
    //     })
    // }

    // const addDomains = () => {  
    //     let domains = [];
    //     allDomains.forEach(d => {
    //         domains.push({
    //             ...d,
    //             is_premium: d.type === 'PREMIUM' ? true : false,
    //             for_sale: d.enabled === 1 ? true : false
    //         })
    //     })
    //     getCurrentUser().then(data => {
    //         console.log('userData: ', data)
    //         api.addDomains(domains, data.userId)
    //         .then(res => {
    //             console.log('addDomains res: ', res);
    //         })
    //         .catch(error => {
    //             console.log('addDomains error: ', error);
    //         })
    //     })
        
    // }

    // const getDomainDetails = () => {
    //     let seller_id = 1
    //     let domain_id = 1
    //     api.getDomainDetails(seller_id, domain_id)
    //     .then(res => {
    //         console.log('getDomainDetails res: ', res);
    //     })
    //     .catch(err => {
    //         console.log("failed to getDomainDetails: ", err);
    //     })
    // }
  
    return (
        <>

        <Stack direction="row" spacing={2}
            sx={{
                flexWrap: 'wrap',
                justifyContent: 'center', 
                padding: '1em',
                gap: '1em'
            }}>
            {/* <Button onClick={handleFetchDomains}>Fetch</Button>
            <Button onClick={addDomains}>Add Domains</Button>
            <Button onClick={getDomainDetails}>Get Domain details</Button> */}
            {/* <Button onClick={handleFetchCategories}>Fetch categories</Button> */}
            {/* <Button onClick={handleCreateCategories}>Create categories</Button>
            <Button onClick={handleDeleteCategory}>Delete category</Button> */}
            {filters.searchTerms.length > 0 && filters.searchTerms.map((term, i) => 
                <Chip
                    key={`term-${i}`} 
                    label={`"${term}"`} 
                    onDelete={() => handleDeleteSearchTerm(term)} 
                />
            )}

            {(filters.minPrice !== 0 || filters.maxPrice !== FiltersService.absoluteMaxPrice) &&
                <Chip
                    label={`Price: ${formatCurrency(filters.minPrice)} - ${formatCurrency(filters.maxPrice)}${filters.maxPrice === FiltersService.absoluteMaxPrice ? "+" : ""}`}
                    onDelete={handleClearPriceFilter}
                    onClick={toggleFilterDrawer}/>
                
            }

            {filters.styles.length > 0 && (
                filters.styles.map(s => 
                    <Chip
                        key={`style-${s}`}
                        label={`Style: ${s}`}
                        onDelete={() => handleRemoveStyleFilter(s)}
                        onClick={toggleFilterDrawer}/>    
                )
            )}

            {filters.extensions.length > 0 && (
                filters.extensions.map(e => 
                    <Chip
                        key={`extension-${e}`}
                        label={`.${e}`}
                        onDelete={() => handleRemoveExtensionFilter(e)}
                        onClick={toggleFilterDrawer}/>    
                )
            )}

            {!filters.includeMakeOffer && 
                <Chip label={'Exclude make offer'} onDelete={handleRemoveBINOnly} onClick={toggleFilterDrawer}/>
            }

            {filters.categoryIds.length > 0 &&
                <Chip 
                    label={`${filters.categoryIds.length} Categories`}
                    onDelete={handleRemoveCategories}
                    onClick={toggleFilterDrawer}
                />
            }
        </Stack>
        </>
    );
};

export default FiltersBar;