import React, {useState, useEffect} from 'react';
import { 
    Container, 
    Box,
    Typography,
    Paper,
    Tabs,
    Tab
} from '@mui/material';
import MainNav from '../../../components/MainNav/MainNav';
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import PropTypes from 'prop-types';
import { AccountSettings } from '@aws-amplify/ui-react';
import MyDomains from '../MyDomains/MyDomains';
// 'aws-amplify/auth' (possible exports: AuthError, autoSignIn, confirmResetPassword, confirmSignIn, confirmSignUp, confirmUserAttribute, decodeJWT, deleteUser, deleteUserAttributes, fetchAuthSession, fetchDevices, fetchMFAPreference, fetchUserAttributes, forgetDevice, getCurrentUser, rememberDevice, resendSignUpCode, resetPassword, sendUserAttributeVerificationCode, setUpTOTP, signIn, signInWithRedirect, signOut, signUp, updateMFAPreference, updatePassword, updateUserAttribute, updateUserAttributes, verifyTOTPSetup)

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
}
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DashboardHome = () => {
    const [loading, setLoading] = useState(true);
    const [authSession, setAuthSession] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {

        fetchAuthSession()
        .then(info => {
            console.log('authSession: ', info)
            setAuthSession(info);
            setLoading(false)
        })
        .catch(err => {
            console.log(err);
            navigate('/login');
        })
        
    }, [navigate]);

    const handlePasswordChangeSuccess = () => {
        alert('password is successfully changed!')
        handleTabChange(0)
    }

    const handleTabChange = (newValue) => {
        setSelectedTabIndex(newValue);
      };

    return (
        <>
        <MainNav/>
        <Container sx={{paddingTop: '5rem', paddingBottom: '5em'}}>
            
            {!loading &&
                <>
                <Box sx={{paddingTop: '2em'}}>
                    <Typography variant="h5">Welcome back {authSession.tokens.idToken.payload.name}</Typography>
                </Box>
                
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: '2em' }}>
                        <Tabs value={selectedTabIndex} onChange={(e, newValue) => handleTabChange(newValue)} aria-label="basic tabs example">
                            <Tab label="My Domains" {...a11yProps(0)} />
                            <Tab label="Settings" {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    <CustomTabPanel value={selectedTabIndex} index={0}>
                        <MyDomains/>
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTabIndex} index={1}>
                        <Container sx={{paddingTop: '4em', display: 'flex', justifyContent: 'center'}}>
                            <Paper elevation={3} sx={{padding: '1em', width: 'fit-content', minWidth: '325px'}}>
                                <Typography variant='h5' gutterBottom>Reset password</Typography>
                                <AccountSettings.ChangePassword onSuccess={handlePasswordChangeSuccess}/>
                            </Paper>
                        </Container>
                    </CustomTabPanel>
                </Box>
                </>
            }
        </Container>
        </>
    );
};

export default DashboardHome;