import React, {useState} from 'react';
import { 
    Container, 
    Box,
    Typography, 
} from '@mui/material';
import MainNav from '../../components/MainNav/MainNav';
import './style.css'
import SearchBar from '../../components/SearchFilters/SearchBar/SearchBar';


const Home = () => {
  
    return (
        <>
        <MainNav notSticky={true}/>
        <Box>
            <div className='ocean-container'>
                <div className="ocean">
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                
                <Box sx={{
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '100%',
                    backgroundColor: 'var(--dl-dark-blue)'}}>
                        
                    <div style={{width: '475px', maxWidth: '100%'}}>
                        <Container>
                        <Typography variant='h1' sx={{fontSize: '36px', color: 'white'}} gutterBottom>Find the perfect domain</Typography>
                            <SearchBar uniqueId="home-page-search" navigateToSearchPageOnEnter={true} size='large'/>
                        </Container>
                    </div>
                </Box>

            </div> 
        </Box>
        
        <div className="home-body">
            <div className='animated-wave'></div>
        </div>
        <div className="home-footer"></div>
       
        {/* <Container sx={{paddingTop: '5rem'}}>
            
            

        </Container> */}
        </>
    );

    // return (
    //     <>
    //     <div className="ocean">
    //         <div className="wave"></div>
    //         <div className="wave"></div>
    //     </div>
       
    //     <Container sx={{paddingTop: '5rem'}}>
    //         <MainNav/>
    //         <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '5em'}}>
    //             <div style={{width: '400px', maxWidth: '100%'}}>
    //                 <SearchBar uniqueId="home-page-search" navigateToSearchPageOnEnter={true}/>
    //             </div>
    //         </Box>
    //         <Box sx={{position: 'static'}}>
            
    //         </Box>
            
            
            
    //     </Container>
    //     </>
    // );
};

export default Home;