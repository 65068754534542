import React, {useState, useEffect} from 'react';
import {  
    Box, 
    Slider
} from '@mui/material';
import { FiltersService } from '../../../services/Filters/Filters.service';

function valuetext(value) {
  return `$${value}`;
}

const PriceSlider = ({uniqueId}) => {
  const [range, setRange] = useState([FiltersService.filters.minPrice, FiltersService.filters.maxPrice]);

  useEffect(() => {
    FiltersService.subscribe(uniqueId, handleFiltersUpdated);

    return () => {
        FiltersService.unsubscribe(uniqueId);
    }
  }, [uniqueId]);

  const handleFiltersUpdated = (filters) => {
    setRange([filters.minPrice, filters.maxPrice])
  }

  const handleChange = (event, newValue) => {
    setRange(newValue)
  };

  const handleChangeCommitted = (event, newValue) => {
    console.log('handleChangeCommitted - newValue: ', newValue)
    if(newValue.length === 2){
        FiltersService.filters = {
            ...FiltersService.filters,
            minPrice: newValue[0],
            maxPrice: newValue[1]
        }
    }
  };

  return (
    <>
    <Box 
      sx={{ paddingX: '.5em' }}
      >
      <Slider
        getAriaLabel={() => 'Price range'}
        value={range}
        min={0}
        max={FiltersService.absoluteMaxPrice}
        step={10}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        valueLabel={range[1] >= FiltersService.absoluteMaxPrice ? `$${FiltersService.absoluteMaxPrice}+` : `$${range[1]}`}
      />
    </Box>
    </>
    
  );
}

PriceSlider.defaultProps = {
    uniqueId: 'price-slider'
}

export default PriceSlider;