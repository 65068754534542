import { fetchAuthSession } from 'aws-amplify/auth'
import { get, post, put, del } from 'aws-amplify/api';


export const api = {
    getDomains,
    addCategory,
    addDomains,
    getDomainDetails,
    searchDomains
}

async function getToken(){
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    console.log('authToken: ', authToken)
    return authToken;
}

async function getDomains(user_id){
    const token = await getToken();
    try {
        const restOperation = get({ 
          apiName: 'domainsAPI',
          path: '/domains',
          options: {
            queryParams: {
              user_id: user_id
            }
          }
        });
        const response = await restOperation.response;
       
        console.log('GET call succeeded: ', response);
        const data = await response.body.json()
        console.log('data: ', data)
    } catch (error) {
        console.log('GET call failed: ', error);
    }
}

async function searchDomains(filters){
  try {
    const restOperation = post({ 
      apiName: 'domainsAPI',
      path: '/fetch-domains',
      options: {
        body: {
          'filters': filters
        }
      }
    });
    const response = await restOperation.response;
    const data = await response.body.json()
    return data;
  } catch (error) {
      console.log('GET /fetch-domains call failed: ', error);
  }
}

async function getDomainDetails(seller_id, domain_id){
  try {
      const restOperation = get({ 
        apiName: 'domainsAPI',
        path: '/domain-details',
        options: {
          queryParams: {
            seller_id: seller_id,
            domain_id: domain_id
          }
        }
      });
      const response = await restOperation.response;
     
      console.log('GET call succeeded: ', response);
      const data = await response.body.json()
      console.log('data: ', data)
  } catch (error) {
      console.log('GET call failed: ', error);
  }
}

async function addDomains(domains, seller_id){
    try {
        let reqBody = { domains: domains };
        console.log('reqBody', reqBody);
        reqBody = JSON.stringify(reqBody);
        console.log('reqBody', reqBody);
        const restOperation = post({ 
          apiName: 'domainsAPI',
          path: '/add-domains',
          options: {
            queryParams: {
              'seller_id': seller_id
            },
            body: {
              'domains': domains
            }
          }
        });
        const response = await restOperation.response;
       
        console.log('Post call succeeded: ', response);
        const data = await response.body.json()
        console.log('data: ', data)
        return data;
        // const data = await response.body.json()
        // console.log('data: ', data)
    } catch (error) {
        console.log('POST call failed: ', error);
    }
}

async function addCategory(){
    try {
        const restOperation = post({ 
          apiName: 'dlAPI',
          path: '/categories',
        });
        const response = await restOperation.response;
       
        console.log('Post call succeeded: ', response);
        // const data = await response.body.json()
        // console.log('data: ', data)
    } catch (error) {
        console.log('POST call failed: ', error);
    }
}
