import React, {useState, useEffect, useMemo} from 'react';
import {  
    Box, 
    IconButton, 
    InputAdornment, 
    TextField
} from '@mui/material';
import { SearchSharp } from '@mui/icons-material';
import { FiltersService } from '../../../services/Filters/Filters.service';
import ClearIcon from '@mui/icons-material/Clear';
import TuneIcon from '@mui/icons-material/Tune';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

const SearchBar = ({uniqueId, handleFilterIconClicked, navigateToSearchPageOnEnter, size='small'}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleKeyDown = (e) => {
        if(e.keyCode === 13 && e.target.value.length > 0){
            console.log('enter pressed: ', e.target.value);
            let lowercaseTerms = []
            console.log('existing search terms: ', FiltersService.filters.searchTerms)
            FiltersService.filters.searchTerms.forEach(t => {
                lowercaseTerms.push(t.toLowerCase())
            })
            // if(FiltersService.filters.searchTerms.length > 0){
            //     FiltersService.filters.searchTerms.forEach(t => {
            //         lowercaseTerms.push(t.toLowerCase())
            //     })
            // }
            if(navigateToSearchPageOnEnter && navigateToSearchPageOnEnter==true){
                FiltersService._filters = {
                    ...FiltersService.defaultFilters(),
                    'searchTerms': [e.target.value.toLowerCase()]
                }
                navigate('search')
            }else{
                if(!lowercaseTerms.includes(e.target.value.toLowerCase())){
                    FiltersService.setFilter('searchTerms', [...FiltersService.filters.searchTerms, e.target.value])
                }
                
                clearSearchField()
            }
            
        }
    }

    const clearSearchField = () => {
        setSearchTerm('');
    }

  
    return (
        <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'center', }}>
            <TextField 
                id='searchField' 
                fullWidth
                sx={{
                    backgroundColor: 'white', 
                    // maxWidth:"400px", 
                    borderRadius: '10px'
                }} 
                placeholder="Search..." 
                size={size}
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                    style: {borderRadius: '10px'},
                    startAdornment:
                        <InputAdornment position='start'>
                            <SearchSharp sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        </InputAdornment>,
                    endAdornment: 
                        <>
                            {searchTerm !== '' && 
                                <InputAdornment position='end'>
                                    <IconButton 
                                        aria-label="clear-search-field"
                                        onClick={clearSearchField}
                                        edge="end">
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            {!navigateToSearchPageOnEnter &&
                                <InputAdornment position='end'>
                                    <IconButton 
                                        aria-label="open-filters"
                                        onClick={handleFilterIconClicked}
                                        edge="end">
                                        <TuneIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                        </>
                }}
                />
        </Box>
    );
};

export default SearchBar;

// import React, {useState, useEffect, useMemo} from 'react';
// import {  
//     Box, 
//     IconButton, 
//     InputAdornment, 
//     TextField
// } from '@mui/material';
// import { SearchSharp } from '@mui/icons-material';
// import { FiltersService } from '../../../services/Filters/Filters.service';
// import ClearIcon from '@mui/icons-material/Clear';
// import TuneIcon from '@mui/icons-material/Tune';
// // import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import { debounce } from 'lodash';


// const SearchBar = ({uniqueId, handleFilterIconClicked}) => {
//     const [filters, setFilters] = useState(FiltersService.filters);

//     useEffect(() => {
//         FiltersService.subscribe(uniqueId, handleFiltersUpdated);
    
//         return () => {
//             FiltersService.unsubscribe(uniqueId);
//         }
//     }, [uniqueId]);
    
//     const  handleFiltersUpdated = (filters) => {
//         setFilters(filters)
//     }

//     const handleSearchChange = (e) => {
//         FiltersService.setFilter('searchTerm', e.target.value)
//     }

//     const clearSearchField = () => {
//         document.getElementById('searchField').value = ''
//         FiltersService.setFilter('searchTerm', '')
//     }

//     const debouncedHandleSearchChange = useMemo(() => debounce(handleSearchChange, 200), [])

  
//     return (
//         <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'center', }}>
//             <TextField 
//                 id='searchField' 
//                 fullWidth
//                 sx={{backgroundColor: 'white', maxWidth:"400px"}} 
//                 placeholder="Search..." 
//                 size="small" 
//                 onChange={debouncedHandleSearchChange}
//                 InputProps={{
//                     startAdornment:
//                         <InputAdornment position='start'>
//                             <SearchSharp sx={{color: 'action.active', mr: 1, my: 0.5}}/>
//                         </InputAdornment>,
//                     endAdornment: 
//                         <>
//                             {filters.searchTerm !== '' && 
//                                 <InputAdornment position='end'>
//                                     <IconButton 
//                                         aria-label="clear-search-field"
//                                         onClick={clearSearchField}
//                                         edge="end">
//                                         <ClearIcon/>
//                                     </IconButton>
//                                 </InputAdornment>
//                             }
//                             <InputAdornment position='end'>
//                                 <IconButton 
//                                     aria-label="open-filters"
//                                     onClick={handleFilterIconClicked}
//                                     edge="end">
//                                     <TuneIcon/>
//                                 </IconButton>
//                             </InputAdornment>
//                         </>
//                 }}
//                 />
//         </Box>
//     );
// };

// export default SearchBar;