import React, {useState, useEffect} from 'react';
import {  
    Button,
    Box
} from '@mui/material';
import { FiltersService } from '../../../services/Filters/Filters.service';



const ExtensionButtons = ({uniqueId}) => {
    const [selectedExtensions, setSelectedExtensions] = useState(FiltersService.filters.extensions);

    useEffect(() => {
        FiltersService.subscribe(uniqueId, handleFiltersUpdated);
    
        return () => {
            FiltersService.unsubscribe(uniqueId);
        }
    }, [uniqueId]);
    
    const  handleFiltersUpdated = (filters) => {
        setSelectedExtensions(filters.extensions);
    }

    const handleExtensionSelected = (extension) => {
        console.log('extension clicked: ', extension)
        
        if(extension === 'All'){
            FiltersService.setFilter('extensions', [])
        }else{
            if(selectedExtensions.includes(extension)){
                FiltersService.setFilter('extensions', selectedExtensions.filter(e => e !== extension))
            }else{
                FiltersService.setFilter('extensions', [...selectedExtensions, extension])
            }
        }
    }
  
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Button
                onClick={() => handleExtensionSelected('All')}
                variant={selectedExtensions.length === 0 ? 'contained' : 'outlined'}
                size="small"
            >
                All
            </Button>

            {FiltersService.extensionOptions.map(op => 
                <Button 
                    key={`extension-${op}`} 
                    onClick={() => handleExtensionSelected(op)}
                    variant={selectedExtensions.includes(op) ? 'contained' : 'outlined'}
                    size="small"
                >
                    .{op}
                </Button>
            )}
        </Box>
        
    );
};

ExtensionButtons.defaultProps = {
    uniqueId: 'domain-extension-buttons'
}

export default ExtensionButtons;