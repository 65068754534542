import React, {useState, useEffect} from 'react';
import { Drawer, Box, Typography, Divider, IconButton, Stack } from '@mui/material';

import { FiltersService } from '../../services/Filters/Filters.service';
import { SearchService } from '../../services/Search/Search.service';
import PriceSlider from '../SearchFilters/PriceSlider/PriceSlider';
import ShowMakeOfferSwitch from '../SearchFilters/ShowMakeOfferSwitch/ShowMakeOfferSwitch';
import ClearIcon from '@mui/icons-material/Clear';
import MinMaxPriceFields from '../SearchFilters/MinMaxPriceFields/MinMaxPriceFields';
import DomainStyleButtons from '../SearchFilters/DomainStyleButtons/DomainStyleButtons';
import SortDropdown from '../SearchFilters/SortDropdown/SortDropdown';
import Categories from '../SearchFilters/Categories/Categories';
import ExtensionButtons from '../SearchFilters/ExtensionButtons/ExtensionButtons';


const FilterDrawer = ({open, toggleDrawer}) => {
    
    const [filters, setFilters] = useState(FiltersService.filters);
    // const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState(SearchService._searchResults);
    const [visibleResults, setVisibleResults] = useState(SearchService._visibleResults);
    
    useEffect(() => {
        FiltersService.subscribe('FilterDrawer', handleFiltersUpdated);
        SearchService.subscribe('FilterDrawer', handleLoadingStateChanged, handleResultsPublished);

        return () => {
            FiltersService.unsubscribe('FilterDrawer');
            SearchService.unsubscribe('FilterDrawer');
        }
    }, []);

    const handleFiltersUpdated = (filters) => {
        setFilters(filters);
    }

    const handleLoadingStateChanged = (newState) => {
        setLoading(newState);
    }

    const handleResultsPublished = (all, visible) => {
        setSearchResults(all);
        setVisibleResults(visible);
    }

    
  
    return (
        <>
        <React.Fragment key='left'>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer('right', false)}
            >
                <Box sx={{width: 320}} role='presentation'>
                    <Stack sx={{padding: '1.5em'}} spacing={3}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '1.5em'}}>
                            <Typography variant='h6'>Filter results ({searchResults.length})</Typography>
                            <IconButton
                                aria-label="clear-search-field"
                                onClick={toggleDrawer('right', false)}
                                >
                                <ClearIcon/>
                            </IconButton>
                        </Box>

                        <Stack spacing={1}>
                            <Typography variant='subtitle1'>Style</Typography>
                            <DomainStyleButtons uniqueId={'filter-drawer-style-buttons'}/>
                            <Divider/>
                        </Stack>

                        <Stack spacing={1}>
                            <Typography variant='subtitle1'>Extension</Typography>
                            <ExtensionButtons uniqueId={'filter-drawer-extension-buttons'}/>
                            <Divider/>
                        </Stack>

                        <Stack spacing={1}>
                            <Typography variant='subtitle1'>Price</Typography>
                            <PriceSlider uniqueId={'filter-drawer-price-slider'}/>
                            <MinMaxPriceFields uniqueId={'filter-drawer-min-max-fields'}/>
                            <ShowMakeOfferSwitch uniqueId={'filter-drawer-price-slider'}/>
                            <Divider/>
                        </Stack>

                        {/* <Stack spacing={1}>
                            <Categories/>
                            <Divider/>
                        </Stack> */}
                        
                        <Stack spacing={1}>
                            <Typography variant='subtitle1'>Sort</Typography>
                            <SortDropdown uniqueId={'filter-drawer-sort-dropdown'}/>
                            <Divider/>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Drawer>
        </React.Fragment>
        
        </>
    );
   
};

export default FilterDrawer;