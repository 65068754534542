import React, {useState, useEffect} from 'react';
import { 
    Button,
} from '@mui/material';
// import { formatCurrency } from '../../utils/util_functions';
// import { useNavigate } from 'react-router-dom';

const MakeOfferBtn = ({domain}) => {

    return (
    <form action="https://www.escrow-sandbox.com/offer" method="post" target="_blank">
        <input type="hidden" name="type" value="domain_name"/>
        <input type="hidden" name="non_initiator_email" value="sales@domainline.com"/>
        <input type="hidden" name="non_initiator_id" value="1129661"/>
        <input type="hidden" name="non_initiator_role" value="seller"/>
        <input type="hidden" name="title" value={`Purchase of domain name: ${domain.fqdn.toLowerCase()}`}/>
        <input type="hidden" name="currency" value="USD"/>
        <input type="hidden" name="domain" value={`${domain.fqdn.toLowerCase()}`}/>
        <input type="hidden" name="price" value={domain.list_price.toString()}/>
        <input type="hidden" name="concierge" value="false"/>
        <input type="hidden" name="with_content" value="false"/>
        <input type="hidden" name="inspection_period" value="1"/>
        <input type="hidden" name="fee_payer" value="seller"/>
        <input type="hidden" name="return_url" value=""/>
        <input type="hidden" name="button_types" value="both"/>
        <input type="hidden" name="auto_accept" value=""/>
        <input type="hidden" name="auto_reject" value=""/>
        <input type="hidden" name="item_key" value={domain.id}/>
        <Button type='submit' color="success" variant="contained" size="large">Make an offer</Button>
        {/* <button class="" type="submit">Buy With Escrow.com</button> */}
        <img src="https://t.escrow-sandbox.com/1px.gif?name=bin&price=10000&title=Purchase%20of%20domain%20name%3A%20tuds.com&user_id=1129661" style={{display: "none"}} alt="escrow-img"/>
    </form>
    );
};

export default MakeOfferBtn;