import * as React from 'react';
import PropTypes from 'prop-types';
// import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import Stack from '@mui/material/Stack';
// import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
import { FiltersService } from '../../../services/Filters/Filters.service';

// const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
//   const { onChange, ...other } = props;
//   return (
//     <IMaskInput
//       {...other}
//       mask="(#00) 000-0000"
//       definitions={{
//         '#': /[1-9]/,
//       }}
//       inputRef={ref}
//       onAccept={(value) => onChange({ target: { name: props.name, value } })}
//       overwrite
//     />
//   );
// });

// TextMaskCustom.propTypes = {
//   name: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
// };

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
      allowNegative={false}
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function MinMaxPriceFields(props) {

  const [range, setRange] = React.useState([FiltersService.filters.minPrice, FiltersService.filters.maxPrice]);

  React.useEffect(() => {
    FiltersService.subscribe(props.uniqueId || 'min-max-price-fields', handleFiltersUpdated);

    return () => {
        FiltersService.unsubscribe(props.uniqueId  || 'min-max-price-fields');
    }
  }, []);

  const handleFiltersUpdated = (filters) => {
    setRange([filters.minPrice, filters.maxPrice])
  }

  const handleChange = (event) => {
    console.log('minMaxPriceFields handleChange, name: ', event.target.name, ' value: ', event.target.value)
    FiltersService.setFilter(event.target.name, +event.target.value);
  };

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        label="Min"
        value={range[0]}
        onChange={handleChange}
        name="minPrice"
        id="min-price-input"
        InputProps={{
          inputComponent: NumericFormatCustom,
        }}
        size="small"
        // variant="standard"
      />
      <TextField
        label="Max"
        value={range[1]}
        onChange={handleChange}
        name="maxPrice"
        id="max-price-input"
        InputProps={{
          inputComponent: NumericFormatCustom,
        }}
        size="small"
        // variant="standard"
      />
    </Stack>
  );
}
