import categories from "../../assets/data/categories.json";
import nestedCategories from '../../assets/data/nested_categories.json';
import { generateClient } from 'aws-amplify/api';
import { listCategories } from "../Queries/categories";
import { api } from "../Api/Api";

class _FiltersService {
    constructor(){
        this.client = generateClient();
        this.subscribers = [];
        this.categoryOptions = [];
        this.nestedCategories = [];
        this.sortOptions = [
            {label: 'A-Z', metric: 'domain', direction: 'asc'},
            {label: 'Z-A', metric: 'domain', direction: 'desc'},
            {label: 'Price ascending', metric: 'price', direction: 'asc'},
            {label: 'Price decending', metric: 'price', direction: 'desc'},
        ]
        this.styleOptions = ['Invented', 'Keyword'];
        this.extensionOptions = ['com', 'io', 'ly'];
        this.absoluteMaxPrice = 25000
        this._filters = this.defaultFilters();
    }

    defaultFilters(){
        return {
            searchTerms: [],
            styles: [],
            extensions: [],
            tags: [],
            sortBy: this.sortOptions[0].metric,
            sortDirection: this.sortOptions[0].direction,
            selectedSort: this.sortOptions[0],
            minPrice: 0,
            maxPrice: this.absoluteMaxPrice,
            includeMakeOffer: true,
            categoryIds: [],
            perPage: 16,
            offset: 0
        }
    }

    // async deleteCategory(){
    //     const result = await this.client.graphql({
    //         query: deleteCategory,
    //         variables: {
    //             input: {
    //                 id: 'a9dee3ef-1138-44a6-92fe-2047e90ae4b3'
    //             }
    //         }
    //     })
    //     console.log('delete result: ', result)
    //     return result
    // }

    // async createAllCategories(){
    //     let resArray = []
    //     this.categoryOptions.forEach(async (cat) => {
    //         let res = await this.client.graphql({
    //             query: createCategory,
    //             variables: {
    //                 input: {
    //                     id: cat.id,
    //                     name: cat.name,
    //                     parent_id: cat.parent_id
    //                 }
    //             }
    //         });
    //         resArray.push(res);
    //     })
    //     console.log("resArray: ", resArray)
    //     return resArray;
    // }

    // async createSingleCategory(){
    //     const result = await this.client.graphql({
    //         query: createCategory,
    //         variables: {
    //             input: {
    //                 name: "Business",
    //                 parent_id: null
    //             }
    //         }
    //     });
    //     console.log('createCategory result: ', result)
    //     return result
    // }

    async getCategoryOptions(){
        if(this.categoryOptions.length > 0){
            return this.categoryOptions;
        }else{
            const all = await this.fetchCategories();
            this.categoryOptions = all;
            return all;
        }
    }

    async fetchCategories(){
        const result = await this.client.graphql({
            query: listCategories
        });
        let all = [];
        result.data.listCategories.items.forEach(c => {
            all.push({
                id: +c.id,
                name: c.name,
                parent_id: c.parent_id
            })
        })
        this.categoryOptions = all;
        console.log('fetchCategories: ', this.categoryOptions);
        return this.categoryOptions;
    }

    async getDomains(){
        const res = await api.getDomains();
        console.log(res);
    }

    async addCategory(){
        const res = await api.addCategory();
        console.log(res);
    }

    getNestedCategories(all){
        console.log('getNestedCategories, all: ', all);
        
        let parentCategories = [];
        all.forEach(c => {
            if(c.parent_id === null){
                parentCategories.push({
                    ...c,
                    nested: []
                });
            }
        })
        parentCategories.forEach(p => {
            all.forEach(c => {
                if(c.parent_id === p.id){
                    p.nested.push(c);
                }
            })
        })
        return parentCategories;
    }

    subscribe(name, callback){
        const index = this.subscribers.findIndex(sub => sub.name === name);
        if(index > -1){
            this.subscribers[index] = { name, callback };
        }else{
            this.subscribers.push({name, callback});
        }
    }

    unsubscribe(name){
        const index = this.subscribers.findIndex(sub => sub.name === name);
        if(index > -1){
            this.subscribers.splice(index, 1);
        }
    }

    set filters(value){
        this._filters = value;
        this.publish();
    }

    get filters(){
        if(!this._filters){
            this._filters = this.defaultFilters();
        }
        return this._filters;
    }

    updatePaginationValuesFromSearchResults(limit, offset){
        this._filters['perPage'] = limit;
        this._filters['offset'] = offset;
        this.subscribers.forEach(sub => {
            if(sub.name !== 'SearchService'){
                sub.callback(this.filters)
            }
        });
    }

    setFilter(key, value){
        if(key !== 'offset'){
            this._filters['offset'] = 0;
        }
        // if(key !== 'pageNum'){
        //     this._filters['pageNum'] = 0;
        // }

        this._filters[key] = value;
        
        this.publish();
    }

    resetFilters(){
        this._filters = this.defaultFilters();
        this.publish();
    }

    publish(){
        this.subscribers.forEach(sub => sub.callback(this.filters));
    }
}

export const FiltersService = new _FiltersService();