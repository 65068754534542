import { FiltersService } from "../Filters/Filters.service";
// import categories from "../../assets/data/categories.json";
import domains from "../../assets/data/domains.json";
import { api } from "../Api/Api";

class _SearchService {
    constructor(){
        // this.categories = categories;
        this.domains = domains;
        this.subscribers = [];
        this._searchResults = [];
        this._visibleResults = [];
        this._limit = FiltersService.filters.perPage;
        this._offset = FiltersService.filters.offset;
        this._totalResults = 0;
        this.lastRequestDate = null;
        FiltersService.subscribe('SearchService', this.handleFiltersChanged.bind(this));
        // this.filterResults(FiltersService.filters);
        this.mockLoadingPeriod = 200;
    }

    


    subscribe(name, loadingCallback, resultsCallback){
        const index = this.subscribers.findIndex(sub => sub.name === name);
        if(index > -1){
            this.subscribers[index] = { name, loadingCallback, resultsCallback };
        }else{
            this.subscribers.push({ name, loadingCallback, resultsCallback });
        }
    }

    unsubscribe(name){
        const index = this.subscribers.findIndex(sub => sub.name === name);
        if(index > -1){
            this.subscribers.splice(index, 1);
        }
    }

    filterResults(filters){
        let filtered = [];
        domains.forEach(domain => {
            // filter makeOffer domains
            if(domain.list_price <= 0 && filters.includeMakeOffer === false){
                return;
            }

            // filter by price
            if(filters.minPrice !== 0 || filters.maxPrice !== FiltersService.absoluteMaxPrice){
                if(domain.list_price > filters.maxPrice || filters.minPrice > domain.list_price){
                    return;
                }
            }

            //filter by extension
            if(filters.extensions.length > 0 && !filters.extensions.includes(domain.extension)){
                return;
            }

            // filter by style (all, keyword, or invented)
            if(filters.styles.length > 0){
                if(domain.style === null){
                    return;
                }else{
                    let domainMatchesStyleFilter = false;
                    filters.styles.forEach(style => {
                        if(style.toLowerCase() === domain.style.toLowerCase()){
                            domainMatchesStyleFilter = true;
                        }
                    })
                    if(!domainMatchesStyleFilter){
                        return;
                    }
                }
            }

            if(filters.categoryIds.length > 0){
                if(domain.category_ids.length === 0){
                    return;
                }else{
                    let hasCategoryMatch = false;
                    domain.category_ids.forEach(id => {
                        if(filters.categoryIds.includes(id)){
                            hasCategoryMatch = true;
                        }
                    })

                    if(!hasCategoryMatch){
                        return;
                    }
                }
                

            }

            // filter by search term
            if(filters.searchTerms.length > 0){
                let domainMatches = false;
                filters.searchTerms.forEach(term => {
                    if(!domainMatches){
                        if(domain.fqdn.toLowerCase().includes(term.toLowerCase())){
                            domainMatches = true;
                        }else{
                            domain.tags.forEach(tag => {
                                if(tag.toLowerCase().includes(term.toLowerCase())){
                                    domainMatches = true;
                                }
                            });
                        }
                        if(domainMatches){
                            filtered.push(domain)
                        }
                    }
                })
            }else{
                filtered.push(domain);
            }
            
        })

        // sort domains
        filtered.sort((a,b) => {
            if(filters.selectedSort.metric === 'price'){
                if(filters.selectedSort.direction === 'asc'){
                    return a.list_price-b.list_price;
                }else{
                    return b.list_price-a.list_price;
                }
            }
            if(filters.selectedSort.metric === 'domain'){
                if(filters.selectedSort.direction === 'asc'){
                    return a.fqdn.toLowerCase().localeCompare(b.fqdn.toLowerCase());
                }else{
                    return b.fqdn.toLowerCase().localeCompare(a.fqdn.toLowerCase());
                }
            }
            return 0;
        })

        this._visibleResults = filtered.slice(filters.pageNum*filters.perPage, (filters.pageNum+1)*filters.perPage);
        this._searchResults = filtered;
    }

    // handleFiltersChanged(filters){
    //     const requestDate = new Date().getTime();
    //     this.lastRequestDate = requestDate;

    //     if(this.mockLoadingPeriod === 0){
    //         this.filterResults(filters);
    //         this.publishSearchResults();
    //     }else{
    //         this.publishLoadingState();

    //         setTimeout(() => {
    //             if(this.lastRequestDate === null || requestDate >= this.lastRequestDate){
    //                 this.filterResults(filters);
    //                 this.publishSearchResults();
    //             }
    //         }, this.mockLoadingPeriod);
    //     }
    // }


    handleFiltersChanged(filters=null){
        if(filters == null){
            filters = FiltersService._filters;
        }
        const requestDate = new Date().getTime();
        this.lastRequestDate = requestDate;
        this.publishLoadingState();
        api.searchDomains(filters)
        .then((res) => {
            if(this.lastRequestDate === null || requestDate >= this.lastRequestDate){
                console.log('res: ', res)
                // throw(new Error('err'));
                this._visibleResults = res.domains;
                this._limit = res.limit;
                this._offset = res.offset;
                this._totalResults = res.total
                FiltersService.updatePaginationValuesFromSearchResults(this._limit, this._offset);
                this.publishSearchResults()
            }
        })
        .catch(err => {
            console.log('caught err: ', err);
            if(this.lastRequestDate === null || requestDate >= this.lastRequestDate){
                this._visibleResults = [];
                this._totalResults = 0;
                
                this.publishSearchResults(false); // false -> fetch was unsuccessfull
            }
        })

    }

    publishLoadingState(){
        this.subscribers.forEach(sub => {
            if(sub.loadingCallback){
                sub.loadingCallback(true);
            }
        });
    }

    publishSearchResults(fetchSuccessful = true){
        this.subscribers.forEach(sub => {
            if(sub.loadingCallback){
                sub.loadingCallback(false);
            }
            if(sub.resultsCallback){
                sub.resultsCallback(this._visibleResults, this._totalResults, fetchSuccessful);
            }
        })
    }
}

export const SearchService = new _SearchService();

