import React, {useState, useEffect} from 'react';
import {  
    Switch,
    FormControlLabel
} from '@mui/material';
import { FiltersService } from '../../../services/Filters/Filters.service';



const ShowMakeOfferSwitch = ({uniqueId}) => {
    const [includeMakeOffer, setIncludeMakeOffer] = useState(FiltersService.filters.includeMakeOffer)

    useEffect(() => {
        FiltersService.subscribe(uniqueId, handleFiltersUpdated);
    
        return () => {
            FiltersService.unsubscribe(uniqueId);
        }
    }, []);
    
    const  handleFiltersUpdated = (filters) => {
        setIncludeMakeOffer(filters.includeMakeOffer);
    }

    const handleChange = (e) => {
        FiltersService.setFilter('includeMakeOffer', e.target.checked)
    }
  
    return (
        <FormControlLabel
          control={
            <Switch 
                inputProps={{'aria-label': 'Toggle show make offer domains'}}
                label="Show make offer"
                checked={includeMakeOffer}
                onChange={handleChange}
                sx={{marginLeft: '-10px'}}
            />
          }
          label="Show make offer"
          labelPlacement="start"
          sx={{flexDirection: 'row'}}
        />
        
    );
};

ShowMakeOfferSwitch.defaultProps = {
    uniqueId: 'toggle-show-make-offer'
}

export default ShowMakeOfferSwitch;