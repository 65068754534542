import React, {useState, useEffect} from 'react';
import {  
    Box,
    FormControlLabel,
    Checkbox,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import { FiltersService } from '../../../services/Filters/Filters.service';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Categories = ({uniqueId}) => {
    const [loading, setLoading] = useState(true);
    const [allCategories, setAllCategories] = useState([]);
    const [nestedCategories, setNestedCategories] = useState([]);
    const [categoryIds, setCategoryIds] = useState(FiltersService.filters.categoryIds)
    const [mainAccordionOpen, setMainAccordionOpen] = useState(true);
    const [expandedParent, setExpandedParent] = useState(null);

    const toggleParentAccordion = (parentId) => {
        setExpandedParent(expandedParent === parentId ? null : parentId);
    }

    useEffect(() => {
        FiltersService.getCategoryOptions()
        .then(all => {
            console.log("categories filter all: ", all);
            let nested = FiltersService.getNestedCategories(all)
            console.log("categories filter nested: ", nested);
            setAllCategories(all);
            setNestedCategories(nested);
            setLoading(false);
        })
        .catch(err => {
            console.log('error getting nested categories: ', err)
            setLoading(false);
        })

        FiltersService.subscribe(uniqueId, handleFiltersUpdated);
    
        return () => {
            FiltersService.unsubscribe(uniqueId);
        }
    }, []);
    
    const handleMainAccordionChange = () => (e, newState) => {
        setMainAccordionOpen(newState);
        setExpandedParent(null);
    }

    const  handleFiltersUpdated = (filters) => {
        setCategoryIds(filters.categoryIds)
    }

    const handleParentToggled = (e, parent_id) => {
        let newValue = [];
        allCategories.forEach(c => {
            if(c.id === parent_id || c.parent_id === parent_id){
                if(e.target.checked){
                    newValue.push(c.id);
                }
            }else if(categoryIds.includes(c.id)){
                newValue.push(c.id)
            }
        });
        setExpandedParent(e.target.checked ? parent_id : (expandedParent === parent_id ? null : expandedParent));
        FiltersService.setFilter('categoryIds', newValue);
        
        
    }

    const handleChildToggled = (e, id) => {
        if(e.target.checked && !categoryIds.includes(id)){
            FiltersService.setFilter('categoryIds', [...categoryIds, id]);
        }else{
            FiltersService.setFilter('categoryIds', categoryIds.filter(cId => cId !== id));
        }
    }

    const selectedChildrenCount = (parent) => {
        let count = 0;
        if(categoryIds.includes(parent.id)){
            count+=1;
        }
        parent.nested.forEach(c => {
            if(categoryIds.includes(c.id)){
                count+=1
            }
        })
        if(count > 0){
            return <Typography sx={{ color: 'text.secondary' }}>({count} selected)</Typography>
        }else{
            return null;
        }
    }

    return (
        <Accordion 
            elevation={0} disableGutters
            onChange={handleMainAccordionChange()}
            expanded={mainAccordionOpen}>
            <AccordionSummary id={'all-category-filters'} aria-controls="category-filters" sx={{paddingX: 0}} expandIcon={<ExpandMoreIcon/>}>
                <Typography sx={{ marginRight: '1em'}}>Categories</Typography>
                {categoryIds.length > 0 && <Typography sx={{ color: 'text.secondary' }}>({categoryIds.length} selected)</Typography>}
            </AccordionSummary>

            <AccordionDetails sx={{paddingX: 0}}>
                {nestedCategories.map(p => 
                    <Accordion key={`parent-category-${p.name}`} expanded={expandedParent === p.id} elevation={2} disableGutters sx={{marginTop: 0}}>
                        <AccordionSummary 
                            expandIcon={<ExpandMoreIcon onClick={() => toggleParentAccordion(p.id)}/>}
                            aria-controls={`${p.name}-nested-categories`} id={`parent-category-${p.name}`} sx={{maxHeight: '40px'}}
                        >
                            <div style={{display: 'flex', alignItems: 'center', cursor: 'default'}}>
                                <FormControlLabel
                                    label={p.name}
                                    control={
                                        <Checkbox checked={categoryIds.includes(p.id)} onChange={(e) => handleParentToggled(e, p.id)}/>
                                    }
                                />
                                {selectedChildrenCount(p)}
                            </div>
                            
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                {p.nested.map(c => 
                                    <FormControlLabel
                                        key={`child-category-${c.name}`}
                                        label={c.name}
                                        control={
                                            <Checkbox checked={categoryIds.includes(c.id)} onChange={(e) => handleChildToggled(e, c.id)} size="small"/>
                                        }
                                    />
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

Categories.defaultProps = {
    uniqueId: 'categories-filter'
}

export default Categories;