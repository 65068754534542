import React, {useState, useEffect} from 'react';
import { 
    Container, 
    Pagination,
    Stack,
    Alert,
    Paper,
    Box} from '@mui/material';
import MainNav from '../../components/MainNav/MainNav';
import { FiltersService } from '../../services/Filters/Filters.service';
import { SearchService } from '../../services/Search/Search.service';
import DomainCard from '../../components/DomainCard/DomainCard';
import FiltersBar from '../../components/FiltersBar/FiltersBar';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import SearchBar from '../../components/SearchFilters/SearchBar/SearchBar';
import FilterDrawer from '../../components/FilterDrawer/FilterDrawer';
import DomainDrawer from '../../components/DomainDrawer/DomainDrawer';


const Search = () => {
    
    const [filters, setFilters] = useState(FiltersService.filters);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const [domainDrawerOpen, setDomainDrawerOpen] = useState(false);
    const [domainDrawerDomain, setDomainDrawerDomain] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchResults, setSearchResults] = useState(SearchService._searchResults);
    const [visibleResults, setVisibleResults] = useState(SearchService._visibleResults);
    const [total, setTotal] = useState(SearchService._totalResults);
    const [limit, setLimit] = useState(SearchService._limit);
    const [offset, setOffset] = useState(SearchService._offset);
    const [searchError, setSearchError] = useState(false);

    useEffect(() => {
        handleScrollToTop();
        FiltersService.subscribe('SearchPage', handleFiltersUpdated);
        SearchService.subscribe('SearchPage', handleLoadingStateChanged, handleResultsPublished);
        SearchService.handleFiltersChanged()
        return () => {
            FiltersService.unsubscribe('SearchPage');
            SearchService.unsubscribe('SearchPage');
        }
    }, []);

    const handleFiltersUpdated = (filters) => {
        setFilters(filters);
        handleScrollToTop();
    }

    const handleLoadingStateChanged = (newState) => {
        setLoading(newState);
    }

    const handleResultsPublished = (visible, total, success) => {
        setVisibleResults(visible);
        setTotal(total);
        setSearchError(!success);
    }

    const handlePageChange = (e, page) => {
        FiltersService.setFilter('offset', (page-1)*limit);
    }

    const toggleFilterDrawer = (anchor, open) => (event) => {
        console.log('toggleFilterDrawer: ', open)
        if(event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')){
            return;
        }

        setFilterDrawerOpen(open);
    }


    const toggleDomainDrawer = (domain, open) => (event) => {
        console.log('toggleDomainDrawer: ', open)
        console.log('toggleDomainDrawer: domain ', domain)
        console.log('toggleDomainDrawer: event', event)
        if(event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')){
            return;
        }
        if(open){
            setDomainDrawerDomain(domain);
        }
        setDomainDrawerOpen(open);
    }

    const handleScrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
  
    return (
        <>
        <MainNav>
            <SearchBar uniqueId="search-page-search-bar" handleFilterIconClicked={toggleFilterDrawer('left', true)}/>
        </MainNav>
        <FilterDrawer open={filterDrawerOpen} toggleDrawer={toggleFilterDrawer}/>
        <DomainDrawer open={domainDrawerOpen} toggleDrawer={toggleDomainDrawer} domain={domainDrawerDomain}/>
        <Container sx={{paddingTop: '5rem', paddingBottom: '6rem'}}>
            <FiltersBar toggleFilterDrawer={toggleFilterDrawer('left', true)}/>

            <Grid2 container spacing={3}>
                { loading ?
                    [...Array(filters.perPage)].map((e, i) => 
                        <Grid2 xs={12} sm={6} md={4} lg={3} key={`loading-${i}`}>
                            <DomainCard isLoading={true}/>
                        </Grid2>
                    )
                :
                    <>
                    {(searchError || visibleResults.length === 0) &&
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            {searchError ? 
                                <Alert severity="error" sx={{margin: '.5em', alignItems: 'center'}}>There was an error processing your request. Please try again later</Alert>
                            :
                                <Alert severity="info" sx={{margin: '.5em', alignItems: 'center'}}>No results</Alert>
                            }
                        </Box>
                    }
                    {/* {!searchError && visibleResults.length === 0 &&
                        
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <Alert severity="info" sx={{margin: '.5em', alignItems: 'center'}}>No results</Alert>
                        </Box>
                    } */}

                    {visibleResults.map(d => 
                        <Grid2 xs={12} sm={6} md={4} lg={3} key={d.fqdn}>
                            <DomainCard isLoading={false} domain={d} openDomainDrawer={toggleDomainDrawer(d, true)}/>
                        </Grid2>
                    )}
                    
                    </>
                }
            </Grid2>
            

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <Stack alignItems="center" paddingY={3}>
                    <Pagination 
                        count={Math.ceil(total / filters.perPage)} 
                        page={(filters.offset/filters.perPage)+1} 
                        onChange={handlePageChange}
                        sx={{justifyContent: 'center',}}
                        size="medium"/>
                </Stack>
            </Paper>

        </Container>
        
        </>
    );
   
};

export default Search;