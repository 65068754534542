import React, {useState} from 'react';
import { 
    AppBar, 
    Container, 
    Toolbar, 
    Typography, 
    Box, 
    IconButton, 
    Menu, 
    Avatar,
    Button,
    MenuItem,
    Paper} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, Link } from 'react-router-dom';
import lightLogo from '../../assets/media/domainline-logo-light.png';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";

const MainNav = ({children, notSticky=false}) => {
    const pages = [
        {label: 'Home', path: '/', protected: false}, 
        {label: 'Search', path: '/search', protected: false},
        {label: 'Dashboard', path: '/dashboard', protected: true}
    ];
    // const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const { authStatus, signOut } = useAuthenticator(context => [context.authStatus]);
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    const handleSignOut = () => {
        signOut();
        navigate('/')
    }

   
    return (
        <AppBar 
            sx={ notSticky ? 
                    {backgroundColor: 'var(--dl-dark-blue)', boxShadow: 'none'} :
                    {backgroundColor: 'var(--dl-dark-blue)'} }
            position={notSticky ? 'relative' : 'sticky'}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to={"/"}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={lightLogo} alt="DomainLine" style={{width: '200px'}}/>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                            display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => {
                                    if(!page.protected || (authStatus === 'authenticated')){
                                        return (
                                            <MenuItem key={`menu-link-${page.path}`} onClick={() => navigate(page.path)}>
                                                <Typography textAlign="center">{page.label}</Typography>
                                            </MenuItem>
                                        )
                                    }else{
                                        return null;
                                    }
                                }
                            )}

                            {authStatus === 'authenticated' ?
                                <MenuItem onClick={handleSignOut}>
                                    <Typography textAlign="center">Sign out</Typography>
                                </MenuItem>
                            :
                                <MenuItem onClick={() => navigate('/login')}>
                                    <Typography textAlign="center">Login</Typography>
                                </MenuItem>
                            }
                            
                        </Menu>
                    </Box>
                 
                    <Typography
                        variant="h5"
                        noWrap
                        component={Link}
                        to={"/"}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={lightLogo} alt="DomainLine" style={{width: '200px'}}/>
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => {
                                if(!page.protected || (authStatus === 'authenticated')){
                                    return (
                                        <Button
                                            key={`header-link-${page.path}`}
                                            component={Link}
                                            to={page.path}
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                        >
                                            {page.label}
                                        </Button>
                                    )
                                }else{
                                    return null;
                                }
                            })} 

                            {authStatus === 'authenticated' ?
                                <Button 
                                    onClick={handleSignOut}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Sign out
                                </Button>
                            :
                                <Button 
                                    component={Link} 
                                    to={'/login'}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Login
                                </Button>
                            }
                      
                    </Box>
                
                </Toolbar>
            </Container>
            
            {children && 
            
            <div style={{position: 'absolute', bottom: '-60px', width: '100%', display: 'flex', justifyContent: 'center'}}>

                <Paper elevation={3} 
                    sx={{
                        padding: '10px', 
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        width: '100%',
                        maxWidth: '500px'
                    }}>
                        {children}
                </Paper>
                
            </div>
            }
            
        </AppBar>
    );
};

export default MainNav;