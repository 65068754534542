import { 
    Box,
    Typography,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TablePagination,
    Skeleton,
    Button,
    IconButton
} from "@mui/material";
import React, { useState, useEffect } from 'react';
import { api } from "../../../services/Api/Api";
import { getCurrentUser } from 'aws-amplify/auth';
import { formatCurrency } from "../../../utils/util_functions";
import { Flex } from "@aws-amplify/ui-react";
import AddDomainsDialog from "../../../components/AddDomainsDialog/AddDomainsDialog";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const MyDomains = () => {
    const [loading, setLoading] = useState(true);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [domains, setDomains] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [addDomainDialgoOpen, setAddDomainsDialogOpen] = useState(false);

    // useEffect(() => {
    //     fetchDomains();
    // }, []);
    useEffect(() => {
        getCurrentUser().then(data => {
            setCurrentUserId(data.userId);
            setLoading(true);
            api.searchDomains({
                'limit': limit,
                'offset': offset,
                'seller_id': data.userId
            })
            .then(res => {
                console.log('searchDomains res: ', res);
                setDomains(res.domains);
                setTotal(res.total);
                // setOffset(res.offset);
                // setLimit(res.limit);
                setLoading(false);
            })
            .catch(error => {
                console.log('searchDomains error: ', error);
                setLoading(false);
            })
        })
    }, [limit, offset]);

    // const fetchDomains = () => {
    //     getCurrentUser().then(data => {
    //         setCurrentUserId(data.userId);
    //         setLoading(true);
    //         api.searchDomains({
    //             'limit': limit,
    //             'offset': offset,
    //             'seller_id': data.userId
    //         })
    //         .then(res => {
    //             console.log('searchDomains res: ', res);
    //             setDomains(res.domains);
    //             setTotal(res.total);
    //             setOffset(res.offset);
    //             setLimit(res.limit);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             console.log('searchDomains error: ', error);
    //             setLoading(false);
    //         })
    //     })
    // }

    const columns = [
        {
            id: 'fqdn',
            label: 'Domain',
            minWidth: 170,
            align: 'left',
        },
        {
            id: 'list_price',
            label: 'BIN price',
            minWidth: 170,
            align: 'right',
            format: (value) => formatCurrency(value)
        }
    ]

    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
        console.log('newPage: ', newPage)
        setOffset(newPage*limit);
        // fetchDomains();
    };
    
    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
        setLimit(parseInt(event.target.value, 10));
        setOffset(0);
        // fetchDomains();
    };

    const handleToggleAddDomainsDialog = (newState) => {
        setAddDomainsDialogOpen(newState)
    }

    return (
        <Box sx={{paddingTop: '2em'}}>
            <Typography variant="h5">My Domains {total > 0 ? `(${total})` : null}</Typography>
            <Flex direction='row' justifyContent='flex-end'>
                <Button onClick={()=>handleToggleAddDomainsDialog(true)}>Add Domains</Button>
            </Flex>
            <AddDomainsDialog open={addDomainDialgoOpen} handleClose={() => handleToggleAddDomainsDialog(false)}/>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={3}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((col) => 
                                    <TableCell key={col.id} align={col.align} style={{minWidth: col.minWidth}}>
                                        {col.label}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && 
                                [...Array(limit)].map((e,i) => 
                                    <TableRow>
                                        {columns.map((col) =>                      
                                            <TableCell key={col.id} align={col.align} style={{minWidth: col.minWidth}}>
                                                <Skeleton variant="text"/>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                )
                            }

                            {!loading &&
                                domains.map((d) => (
                                    <TableRow key={d.fqdn} hover role="checkbox" tabIndex={-1}>
                                        {columns.map((col) => {
                                            const value = d[col.id];
                                            return(
                                                <TableCell key={col.id} align={col.align}>
                                                    {col.format && typeof value === 'number'
                                                    ? col.format(value)
                                                    : value}
                                                </TableCell>
                                            )
                                            
                                        })}
                                    </TableRow>
                                ))
                            }
                            {/* {loading ? 
                            <>
                                [...Array(limit)].map((e,i) => 
                                    <TableRow>

                                    </TableRow>
                                )}
                            </>
                            :
                            <>
                            domains.map((d) => (
                                <TableRow key={d.fqdn} hover role="checkbox" tabIndex={-1}>
                                    {columns.map((col) => {
                                        const value = d[col.id];
                                        return(
                                            <TableCell key={col.id} align={col.align}>
                                                {col.format && typeof value === 'number'
                                                ? col.format(value)
                                                : value}
                                            </TableCell>
                                        )
                                        
                                    })}
                                </TableRow>
                            ))
                            </>
                            } */}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[25, 50, 100, 200]}
                                    colSpan={3}
                                    count={total}
                                    rowsPerPage={limit}
                                    page={Math.floor(offset/limit)}
                                    // SelectProps={{
                                    //     inputProps: {
                                    //     'aria-label': 'rows per page',
                                    //     },
                                    //     native: true,
                                    // }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

function TablePaginationActions(props) {
    // const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

export default MyDomains;