import React, {useState, useEffect} from 'react';
import { 
    AppBar, 
    Container, 
    Toolbar, 
    Typography, 
    Box, 
    IconButton, 
    Menu, 
    Avatar,
    Button,
    Tooltip, 
    MenuItem,
    Pagination,
    Card,
    CardContent,
    CardActions,
    Skeleton,
    Chip,
    CardActionArea} from '@mui/material';
import { formatCurrency } from '../../utils/util_functions';
import { useNavigate } from 'react-router-dom';

const DomainCard = ({uniqueKey, domain, openDomainDrawer, isLoading=false}) => {

    const navigate = useNavigate();
    const forceisLoading = true;

    const domainCardClicked = (e) => {
        if(!isLoading && domain){
            // navigate(`/search/${domain.id}`);
            console.log('domainCard - opening drawer')
            openDomainDrawer(e);
        }
    }

    return (
    <Card variant="outlined">
        <CardActionArea 
            onClick={(e) => domainCardClicked(e)}
            // onClick={openDomainDrawer}
        >
            <CardContent>
                <Typography variant='h6' align='center'>
                    {isLoading ? 
                        <Skeleton variant="text" />
                    : domain.fqdn}
                </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: 'space-between', borderTop: '1px solid rgba(0, 0, 0, 0.12)'}}>
                <Typography variant='body2'>
                    {isLoading ? <Skeleton variant="text" width={60}/> : domain.fqdn}   
                </Typography>

                {!isLoading && 
                    <Chip size='small' variant='outlined'
                        label={domain.list_price && domain.list_price > 0 ? formatCurrency(domain.list_price): 'Make offer'}/>
                }
                {/* <Button size="small">
                    {isLoading ? <Skeleton variant="text" width={60}/> :  "View" }
                </Button> */}
            </CardActions>
            {/* <CardActions>
                <Typography variant='p'>
                    {isLoading ? 
                        <Skeleton variant="text" width={60}/>
                    :   
                        <Chip label={domain.list_price && domain.list_price > 0 ? formatCurrency(domain.list_price): 'Make offer'}/>
                    }
                </Typography>
                <Button size="small">
                    {isLoading ? <Skeleton variant="text" width={60}/> :  "View" }
                </Button>
            </CardActions> */}
        </CardActionArea>
        
        {/* <CardActions>
            <Typography variant='p'>
                {isLoading ? 
                    <Skeleton variant="text" width={60}/>
                :   
                    <Chip label={domain.list_price && domain.list_price > 0 ? formatCurrency(domain.list_price): 'Make offer'}/>
                }
            </Typography>
            <Button size="small">
                {isLoading ? <Skeleton variant="text" width={60}/> :  "View" }
            </Button>
        </CardActions> */}
    </Card>
    );
};

export default DomainCard;